import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import cloudImage from "../../src/images/cloud.png";
import cloudImageBlack from "../../src/images/cloudblack.png";

const CLOUD_SIZE = 250; // size of the images in pixels
const NUM_CLOUDS = 10; // number of images

const cloudMovement = keyframes`
from {
 transform: rotate(0deg) translateX(10px) rotate(0deg) ;
 
}
to {
 transform: rotate(-360deg) translateX(10px) rotate(360deg) ;
}
`;

const fixedCloudMovement = keyframes`
from {
 transform: rotate(0deg) translateX(6px) rotate(0deg) ;
 
}
to {
 transform: rotate(360deg) translateX(6px) rotate(-360deg) ;
 
}
`;

const CloudContainer = styled.div`
  position: absolute;
  width: 436px;
  height: 436px;
  border-radius: 50%;
  overflow: hidden;
`;

const FixedCloud = styled.div.attrs(props => ({
  style: {
    opacity: `${props.percentage / 100 - 0.3}`,
  }}))`
  width: 606px;
  height: 310px;
  position: absolute;
  background-image: url(${cloudImageBlack});
  background-size: contain;
  background-repeat: no-repeat;
  left: -60px;
  top: 51px;
  rotate: 9deg;
  animation-name: ${fixedCloudMovement};
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

const CloudItem = styled.div.attrs(props => ({
  style: {
    width: `${props.cloudSize}px`,
    height: `${props.cloudSize}px`,
    top: `${props.top}px`,
    left: `${props.left}px`,
    opacity: `${props.show ? "0.4" : "0"}`,
    animationDuration: `${props.animDuration}s`,
}}))`
  position: absolute;
  background-image: url(${cloudImage});
  background-size: contain;
  background-repeat: no-repeat;
  transition: opacity 1s;
  animation-name: ${cloudMovement};
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;



const Clouds = ({ percentage }) => {
  // console.log("Cloud %", percentage);

  const [clouds, setClouds] = React.useState([])

  useEffect(() => {
    const clouds = [];
    for (let i = 0; i < NUM_CLOUDS; i++) {
      clouds.push({
        top: Math.floor(Math.random() * CLOUD_SIZE) - 50,
        left: Math.floor(Math.random() * CLOUD_SIZE) - 100,
        animDuration: Math.floor(Math.random() * 10) + 5,
        cloudSize: Math.floor(Math.random() * CLOUD_SIZE) + CLOUD_SIZE,
      });
    }
    setClouds(clouds);
  }, []);

  return (
    <CloudContainer>
      <FixedCloud percentage={percentage} />
      {clouds.map((item, index) => {
        // console.log("SHOW", percentage / index / 10);

        return (
          <CloudItem
            key={index}
            top={item.top}
            left={item.left}
            show={percentage / index / 10 >= 1} //
            animDuration={item.animDuration}
            cloudSize={item.cloudSize}
          />
        );
      })}
    </CloudContainer>
  );
};

export default Clouds;
