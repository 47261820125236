import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Input } from "semantic-ui-react";
import { Toggle } from "@fluentui/react/lib/Toggle";
import Slider from "./Slider";
import Clouds from "./Clouds";
import Freja from "../images/Freja.png";
import illustration from "../images/illustration.png";
import LogInForm from "./LogInForm";
import Icons from "./Icons";
import ChooseDestination from "./ChooseDestination";

const Header = styled.div`
  font-weight: 700;
  font-size: 1.9rem;
  text-align: left;
  width: 1200px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 50px;
`;

const Divider = styled.div`
  background-color: lightGrey;
  height: 1px;
  width: 100%;
  margin: 40px 0px;
`;

const FootprintContainer = styled.div`
  width: 1200px;
  height: 100vh;
  height: 800px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  border: 10px solid #d4181f;
`;

const Label = styled.div`
  display: flex;
  padding-bottom: 6px;
  font-weight: 400;
  font-size: 1rem;
  //border: 1px solid;
  gap: 10px;
  align-items: center;
`;

const LabelLegend = styled.div`
  display: flex;
  font-weight: normal;
  font-size: 1rem;
  gap: 16px;
  margin: 17px 0;
  align-items: center;
`;

const LabelWrap = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  color: black;
  font-size: 0.8rem;
`;

const InputFieldWrapper = styled.div`
  display: grid;
  justify-content: ${({justify}) => justify || 'space-between'};
  grid-template-columns: auto auto auto;
  
  input {
    border: 1px solid black;
    border-radius: 3px;
    height: 30px;
    padding-left: 6px;
    width: 140px;
  }
  input::placeholder {
    opacity: 0.5;
  }
  input:focus-visible {
    outline: none;
    border: 1px solid #d4181f;
  }
`;

const TransportTypeButtons = styled.div`
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  display: flex;
  align-items: center;
  gap: 26px;
  margin-top: 10px;
  margin-bottom: -12px;
  div {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    justify-content: flex-start;
    gap: 6px;
  }
  z-index: 1;
`;

const Percentages = styled.div`
  position: absolute;
  border-radius: 4px;
  padding: 16px;
  opacity: 1;
`;

const CircularSliderWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ResultInput = styled.input`
  height: 34px !important;
  width: 144px !important;
  padding: 4px 16px !important;
  font-size: 16px;
  font-weight: bold;
  background-color: #d4181f;
  color: white;
  border: none !important;
  border-radius: 2px;
  ::placeholder {
    color: white;
    opacity: 1 !important;
  }
`;

const LeftColumn = styled.div`
  padding: 20px 40px;
  background-color: white;
  position: relative;
`;
const RightColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  position: relative;
`;

const FrejaLogo = styled.div`
  background-image: url(${Freja});
  background-size: contain;
  background-repeat: no-repeat;
  height: 30px;
  width: 75px;
  position: absolute;
  bottom: 40px;
  right: 44px;
`;

const Illustration = styled.div`
  background-image: url(${illustration});
  background-size: contain;
  background-repeat: no-repeat;
  width: 369px;
  height: 92px;
  position: absolute;
  bottom: 34px;
  left: 40px;
`;

const Footprint = () => {
  const [distance, setDistance] = useState(1000);
  const [load, setLoad] = useState(10);
  const [numTrips, setNumTrips] = useState(1);

  const [toggleTruckHvo, setToggleTruckHvo] = useState(true);
  const [toggleTruck, setToggleTruck] = useState(true);
  const [toggleTrain, setToggleTrain] = useState(true);
  const [toggleShip, setToggleShip] = useState(true);

  const [sliderValues, setSliderValues] = useState({ truckHvo: 0, truck: 0, train: 0, ship: 0 });
  const [cloudPercentage, setCloudPercentage] = useState(50);
  const [grantAccess, setGrantAccess] = useState(false);
  const [message, setMessage] = useState("");

  const [expanded, setExpanded] = useState(false);

  // Static variables
  const ROAD_HVO_EMISSION = useMemo(() => 0.1529, []);
  const ROAD_EMISSION     = useMemo(() => 0.9264, []);
  const TRAIN_EMISSION    = useMemo(() => 0.3282, []);
  const SEA_EMISSION      = useMemo(() => 0.4020, []);
  const TRAILER_TON       = useMemo(() => 25.160, []);

  // Calculate and set START, MIN & MAX values for emission
  useEffect(() => {

    const MAX = 100 * ROAD_EMISSION;

    const emission =
      sliderValues.truckHvo * ROAD_HVO_EMISSION +
      sliderValues.truck * ROAD_EMISSION +
      sliderValues.train * TRAIN_EMISSION +
      sliderValues.ship * SEA_EMISSION;
    
    const cloud = emission / MAX * 100;

    setCloudPercentage(cloud);
  }, [sliderValues, ROAD_HVO_EMISSION, ROAD_EMISSION, TRAIN_EMISSION, SEA_EMISSION]);

  const calcFootprint = () => {
    const road_hvo_footprint = distance * ROAD_HVO_EMISSION * (load / TRAILER_TON) * numTrips * (sliderValues.truckHvo / 100);
    const road_footprint = distance * ROAD_EMISSION * (load / TRAILER_TON) * numTrips * (sliderValues.truck / 100);
    const train_footprint = distance * TRAIN_EMISSION * (load / TRAILER_TON) * numTrips * (sliderValues.train / 100);
    const sea_footprint = distance * SEA_EMISSION * (load / TRAILER_TON) * numTrips * (sliderValues.ship / 100);

    const emission = road_hvo_footprint + road_footprint + train_footprint + sea_footprint;

    return (Math.round(emission * 100) / 100).toFixed(3);
  };

  const calcFootprintTKM = () => {
    const fp = calcFootprint();
    const tkm = distance * load * numTrips;
    const result = ((fp / tkm) * 1000).toFixed(2);
    return isNaN(result) ? 0 : result;
  };

  const handleLogIn = (userData) => {
    if (
      userData.user === process.env.REACT_APP_USER &&
      userData.pass === process.env.REACT_APP_PASS
    ) {
      setGrantAccess(true);
    } else {
      setMessage("Wrong username or password");
    }
  };

  return (
    <>
      {!grantAccess && (
        <>
          <LogInForm
            handleLogIn={handleLogIn}
            grantAccess={grantAccess}
            message={message}
          />
        </>
      )}
      {grantAccess && (
        <FootprintContainer>

          {/* =========== Left ========== */}
          <LeftColumn>
            <Header>FREJA Footprint Analyzer</Header>

            {/* =========== Distance / Load / Number of trips ========== */}
            <InputFieldWrapper>
              <div style={{ position: 'relative' }}>
                <Label>Distance</Label>
                <LabelWrap>
                  
                  <Input
                    placeholder="KM"
                    disabled={expanded}
                    onChange={(e) => setDistance(parseInt(e.target.value) || 0)}
                    value={distance || ""}
                  >
                  </Input>
                    <div style={{ position: 'absolute', right: 10 }}>KM</div>
                </LabelWrap>
              </div>
              <div style={{ position: 'relative' }}>
                <Label>Load</Label>
                <LabelWrap>
                  <Input
                    placeholder="TONS"
                    onChange={(e) => setLoad(parseInt(e.target.value))}
                    value={load || ""}
                  />
                  <div style={{ position: 'absolute', right: 10 }}>TONS</div>
                </LabelWrap>
              </div>
              <div style={{ position: 'relative' }}>
                <Label>Number of trips</Label>
                <LabelWrap>
                  <Input
                    onChange={(e) => setNumTrips(parseInt(e.target.value))}
                    value={numTrips || ""}
                  />
                </LabelWrap>
              </div>
            </InputFieldWrapper>

            {/* =========== Choose Destination =========== */}
            <ChooseDestination expanded={expanded} setExpanded={setExpanded} setDistance={setDistance} />

            <Divider />

            {/* =========== Choose transportation ========== */}
            <Label>Choose transportation</Label>
            <TransportTypeButtons>
              <Toggle
                id="Truck-HVO"
                label={<div>TRUCK HVO</div>}
                onChange={() => setToggleTruckHvo(!toggleTruckHvo)}
                checked={toggleTruckHvo}
                styles={{ pill: { borderColor: '#d01f28' }, thumb: toggleTruckHvo ? {} : { backgroundColor: '#d01f28' } }}
              />
              <Toggle
                id="Truck"
                label={<div>TRUCK</div>}
                onChange={() => setToggleTruck(!toggleTruck)}
                checked={toggleTruck}
                styles={{ pill: { borderColor: '#d01f28' }, thumb: toggleTruck ? {} : { backgroundColor: '#d01f28' } }}
              />
              <Toggle 
                id="Train" 
                label={<div>TRAIN</div>} 
                onChange={() => setToggleTrain(!toggleTrain)}
                checked={toggleTrain}
                styles={{ pill: { borderColor: '#d01f28' }, thumb: toggleTrain ? {} : { backgroundColor: '#d01f28' } }}
              />
              <Toggle 
                id="Ship" 
                label={<div>SHIP</div>} 
                onChange={() => setToggleShip(!toggleShip)}
                checked={toggleShip}
                styles={{ pill: { borderColor: '#d01f28' }, thumb: toggleShip ? {} : { backgroundColor: '#d01f28' } }}
              />
            </TransportTypeButtons>
            <Divider />

            {/* =========== Footprint Results ========== */}
            <InputFieldWrapper justify="flex-start">
              <div style={{ position: 'relative' }}>
                <Label>Footprint</Label>
                <LabelWrap>
                  <ResultInput
                    disabled
                    placeholder="0.000"
                    value={calcFootprint() || ""}
                  />
                  <div style={{ position: 'absolute', right: 10, color: 'white', fontWeight: 'bold' }}>T CO<sub>2</sub>e</div>
                </LabelWrap>
              </div>
              <div style={{ position: 'relative', marginLeft: 40 }}>
                <Label>Footprint / TKM</Label>
                <LabelWrap>
                  <ResultInput
                    disabled
                    placeholder="0.00"
                    value={calcFootprintTKM() || "0.00"}
                  />
                  <div style={{ position: 'absolute', right: 10, color: 'white', fontWeight: 'bold' }}>Kg CO<sub>2</sub>e</div>
                </LabelWrap>
              </div>
            </InputFieldWrapper>
            <Illustration />
          </LeftColumn>

          {/* =========== Right ========== */}
          <RightColumn>
            <CircularSliderWrapper>
              <Clouds percentage={cloudPercentage} />
              <Percentages>
                <LabelLegend>
                  
                  <Icons.Truck bgColor="#0c4b27" />
                  HVO Truck: {Math.round(sliderValues.truckHvo)}%
                </LabelLegend>
                <LabelLegend>
                  <Icons.Truck bgColor={"#d4181f"}/>
                  Truck: {Math.round(sliderValues.truck)}%
                </LabelLegend>
                <LabelLegend>
                  <Icons.Train bgColor={"#313131"} />
                  Train: {Math.round(sliderValues.train)}%
                </LabelLegend>
                <LabelLegend>
                  <Icons.Ship bgColor={"#6a8da7"}/>
                  Ship: {Math.round(sliderValues.ship)}%
                </LabelLegend>
              </Percentages>
              <Slider 
                truck={toggleTruck}
                truckHvo={toggleTruckHvo}
                train={toggleTrain}
                ship={toggleShip}
                sliderChange={setSliderValues} 
              />
            </CircularSliderWrapper>
            <FrejaLogo />
          </RightColumn>

          

          {/* <div
            style={{
              textAlign: "left",
              padding: "20px",
              marginLeft: "33px",
              fontSize: "12px",
            }}
          >
            Cloud: {Math.round(cloudPercentage)}%
          </div> */}
        </FootprintContainer>
      )}
    </>
  );
};

export default Footprint;
