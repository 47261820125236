import "./App.css";
import "./styles/fonts.css";
import styled from "styled-components";
import Footprint from "./components/Footprint";

const AppWrapper = styled.div`
  padding: 0px;
  font-family: DIN-Medium;
  color: #313131;
  margin: 10px 0;
`;

function App() {
  return (
    <AppWrapper>
      <Footprint />
    </AppWrapper>
  );
}

export default App;
