import React, { useState, useEffect, useCallback } from 'react';
import { GoogleApiWrapper } from 'google-maps-react';
import styled from 'styled-components';
import { Input } from 'semantic-ui-react';
import Icons from './Icons';

const TopWrapper = styled.div`
    display: flex;
    margin: 16px 0;
`;

const Toggle = styled.button`
    font-family: DIN-Regular;
    border: none;
    background-color: transparent;
    cursor: ${({ cursor }) => cursor};
    font-size: 13px;
`;

const BottomWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: ${({ expanded }) => (expanded ? '74' : '0')}px;
    
    margin-bottom: -19px;
    transform: scaleY(${({ expanded }) => (expanded ? 1 : 0)});
    transition: all 0.2s linear;
    z-index: 10;
`;

const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 46px 1fr;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  label {
    font-size: 16px;
    position: absolute;
    top: -26px;
  }
  input {
    border: 1px solid black;
    border-radius: 3px;
    height: 30px;
    padding-left: 6px;
    width: 227px;
  }
  input::placeholder {
    opacity: 0.5;
  }
  input:focus-visible {
    outline: none;
    border: 1px solid #d4181f;
  }
  z-index: 10;
`;

const CloseButton = styled.button`
    border: none;
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    right: -6px;
    top: -21px;
    z-index: 10;
`;

const ResultWrapper = styled.div`
  display: ${({show}) => (show ? "flex" : "none")};
  flex-direction: column;
  width: 235px;
  height: 132px;
  overflow: auto;
  top: calc(100% + 2px);
  position: absolute;
  top: 318px;
  background-color: white;
  border: 1px solid black;
  border-radius: 3px;
  padding: 0;
  z-index: 1;
`;

const Result = styled.div`
    font-size: 13px;
    font-family: DIN-Regular;
    cursor: pointer;
    padding: 5px 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
        text-decoration: underline;
    }
    &:focus {
        background-color: #e5e5e5;
        color: black;
    }
    &:focus-visible {
        outline: none;
    }
`;

let currentIndex = 0;
const onKeyDown = (e, length, prefix) => {
    switch (e.key) {
        case 'ArrowDown':
        case 'ArrowUp':
            e.preventDefault();
            const change = e.key === 'ArrowDown' ? 1 : -1;
            let nextIndex = currentIndex + change;
            if (nextIndex < 0) nextIndex = length;
            if (nextIndex >= length + 1) nextIndex = 0;
            const next = document.getElementById(`${prefix}-result-${nextIndex}`);
            next && next.focus();
            currentIndex = nextIndex;
            break;
        case 'Enter':
            e.preventDefault();
            const enterCurrent = document.getElementById(`${prefix}-result-${currentIndex}`);
            const enterInput = document.getElementById(`${prefix}-result-0`);
            enterCurrent && enterCurrent.click();
            enterInput && enterInput.focus();
            break;
        case 'Tab':
            if (e.shiftKey || currentIndex === 0) return;
            e.preventDefault();
            const tabCurrent = document.getElementById(`${prefix}-result-${currentIndex}`);
            tabCurrent && tabCurrent.click();
            if (prefix === 'from') {
                const tabToInput = document.getElementById(`to-result-0`);
                tabToInput && tabToInput.focus();
            }

            break;
        case 'Escape':
            e.preventDefault();
            currentIndex = 0;
            const escInput = document.getElementById(`${prefix}-result-0`);
            escInput && escInput.blur();
            break;
        default:
            break;
    }
}

const DistanceMatrix = ({ google, expanded, setExpanded, setDistance }) => {

    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');

    const [fromResults, setFromResults] = useState([]);
    const [toResults, setToResults] = useState([]);

    useEffect(() => {
        if (!expanded) {

        }
    }, [expanded])

    useEffect(() => {
        const handleClickOutside = (e) => {
            const from = document.getElementById('from-result-0');
            const to = document.getElementById('to-result-0');
            const fromResults = document.getElementById('from-results');
            const toResult = document.getElementById('to-results');
            
            if (!from.contains(e.target) && !fromResults.contains(e.target)) {
                setFromResults([]);
            }
            if (!to.contains(e.target) && !toResult.contains(e.target)) {
                setToResults([]);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleAutoComplete = useCallback(async (input, onclick, clear, prefix) => {
        const service = new google.maps.places.AutocompleteService();
        return service.getPlacePredictions({ input }).then(data => data?.predictions.map((result, index) => (
            <Result
                id={`${prefix}-result-${index + 1}`}
                tabIndex={index}
                key={index}
                onClick={() => {
                    onclick(result.description);
                    setTimeout(clear, 50);
                }}
                onKeyDown={(e) => onKeyDown(e, data.predictions.length, prefix)}
            >
                {result.description}
            </Result>
        )));
    }, [google])
    
    const handleOnChange = useCallback((e, setValue, setResults, prefix) => {
        const input = e.target.value;
        setValue(input);

        if (input.length < 1) return setResults([]);

        // setFromResults([<LoadingSpinner/>]);
        (async () => {
            const results = await handleAutoComplete(input, setValue, () => setResults([]), prefix);
            setResults(results);
        })();
    }, [handleAutoComplete])

    useEffect(() => {
        const service = new google.maps.DistanceMatrixService();

        const origins = [from];
        const destinations = [to];

        service.getDistanceMatrix(
            {
                origins,
                destinations,
                travelMode: 'DRIVING',
            },
            (response, status) => {
                if (status === 'OK') {
                    const distance = Math.round(response?.rows[0]?.elements[0]?.distance?.value / 1000);
                    setDistance(isNaN(distance) ? '0' : distance);
                } else {
                    console.error('Error:', status);
                }
            }
        );
    }, [google, from, to, setDistance]); 

    const handleSwap = useCallback(() => {
        setFrom(to);
        setTo(from);
    }, [from, to]); 

    return (
        <>
            <ResultWrapper id={`from-results`} show={expanded && fromResults.length > 0}>
              {fromResults}
            </ResultWrapper>
            
            <ResultWrapper id={`to-results`} style={{ left: 323 }} show={expanded && toResults.length > 0}>
              {toResults}
            </ResultWrapper>
            
            <TopWrapper>
                <Toggle cursor={expanded ? 'default' : 'pointer'} onClick={() => setExpanded(true)}>
                    <span style={{textDecoration: 'underline'}}>Choose destination</span>
                    <span style={{ marginLeft: 10, marginRight: 10 }}><Icons.MapPin /></span>
                    (optional)
                </Toggle>
            </TopWrapper>
            
            <BottomWrapper expanded={expanded}>
                <InputWrapper>
                    <div style={{ position: 'relative' }}>
                        <label>From</label>
                        <Input 
                            id="from-result-0"
                            onFocus={() => currentIndex = 0}
                            value={from}
                            onChange={e => handleOnChange(e, setFrom, setFromResults, 'from')}
                            onKeyDown={(e) => onKeyDown(e, fromResults.length, 'from')}
                        />

                        
                    </div>

                    <button 
                        tabIndex={-1}
                        onClick={handleSwap}
                        style={{ display: 'flex', justifyContent: 'center', border: 'none', backgroundColor: 'transparent', cursor: 'pointer' }}
                    >
                        <Icons.SwapArrows />
                    </button>
                    
                    <div style={{ width: '100%', position: 'relative' }}>
                        <label>To</label>
                        <Input 
                            id="to-result-0"
                            onFocus={() => currentIndex = 0}
                            value={to}
                            onChange={e => handleOnChange(e, setTo, setToResults, 'to')}
                            onKeyDown={(e) => onKeyDown(e, toResults.length, 'to')}
                        />
                        <CloseButton onClick={() => setExpanded(false)}><Icons.Close /></CloseButton>
                    </div>
                </InputWrapper>
            </BottomWrapper>
        </>
    );
};

const LoadingContainer = () => (
    <TopWrapper style={{ marginBottom: -3 }}>
        <Toggle><span style={{textDecoration: 'underline'}}>Choose destination</span> (optional)</Toggle>
    </TopWrapper>
)

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    LoadingContainer,
})(DistanceMatrix);

